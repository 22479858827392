import { getCurrencyFormatter } from "./formatters";

export class CollectionSortFilterController {
  constructor(availableFilters = []) {
    this.selectedFilters = [];
    this.possible_filters = [
      'material',
      'color',
      'gemstone',
      'green',
      'made_to_order',
      'q',
      'free_eligible',
      'previewable',
      'parent_ring',
      'design_category',
      'price'
    ];

    this.selectedFilters = this.buildAllSelectedFilters(availableFilters);

    this.constructPriceSlider();
    this.hookEvents();

    this.updateDisplayedFilters();
    this.setSortByFromURL();
    $('.filter-bar').toggleClass('collapsible', window.innerWidth <= 800);
  }

  buildAllSelectedFilters(availableFilters) {
		const materials = this.buildFilters(availableFilters.materials, 'material');
		const colors = this.buildFilters(availableFilters.colors, 'color');
		const gemStones = this.buildGemstoneFilter(availableFilters.gem_stones);
		const parentRings = this.buildFilters(availableFilters.parent_rings, 'parent_ring');
		const designCategories = this.buildFiltersBase(availableFilters.design_category, 'design_category', (filter, relatedSearchParamValues) => relatedSearchParamValues?.includes(filter.valueName));
		const price =	this.buildPriceFilter(availableFilters);
		const url = new URL(window.location);
		const availability = [{ type: 'made_to_order', valueName: 'Made to Order', value: true }, { type: 'ready_to_ship', valueName: 'Ready to Ship', value: false }].filter(filter => url.searchParams.get('made_to_order')?.split(',').map(id => id === 'true').includes(filter.value));
		const recycledMetals = [{ type: 'green', valueName: 'Recycled Metal Styles', value: true }].filter(filter => url.searchParams.get(filter.type)?.split(',').map(id => id === 'true').includes(filter.value));
		const freeEligible = [{ type: 'free_eligible', valueName: 'Free Eligible Styles', value: true }].filter(filter => url.searchParams.get(filter.type)?.split(',').map(id => id === 'true').includes(filter.value));
		const previewable = [{ type: 'previewable', valueName: 'Previewable Styles', value: true }].filter(filter => url.searchParams.get(filter.type)?.split(',').map(id => id === 'true').includes(filter.value));
		const query = [{ type: 'q', valueName: url.searchParams.get('q'), value: url.searchParams.get('q') }].filter(filter => url.searchParams.get(filter.type));
		const results = [].concat(materials).concat(colors).concat(gemStones).concat(availability).concat(recycledMetals).concat(price).concat(freeEligible).concat(previewable).concat(query).concat(parentRings).concat(designCategories);
		return results;
	}

  buildGemstoneFilter(availableFilters) {
    const type = 'gemstone';
    const filters = this.buildFilters(availableFilters, type);
    const url = new URL(window.location);
    if (url.searchParams.get(type)?.split(',').map((value => +value)).includes(0)) {
      filters.push(this.buildNoGemstoneFilter());
    }
    return filters;
  }

  buildNoGemstoneFilter() {
    const noGemstoneFilter = {
      type: 'gemstone',
      valueName: 'No Gem Stone',
      value: 0
    };
    return noGemstoneFilter;
  }

  buildFilters(availableFilters, type) {
		return this.buildFiltersBase(availableFilters, type, (filter, relatedSearchParamValues) => relatedSearchParamValues?.map(id => +id)?.includes(filter.value));
	}

	buildFiltersBase(availableFilters, type, compare) {
		const url = new URL(window.location);
		return availableFilters.map(obj => ({type, valueName: obj.name, value: obj.id})).filter(filter => compare(filter, url.searchParams.get(type)?.split(',')));
	}

  buildPriceFilter() {
		const url = new URL(window.location);
		const priceParamValue = url.searchParams.get('price');
		if (priceParamValue) {
			const splitPriceParamValue = priceParamValue.split('-');
			const formattedValues = getCurrencyFormatter().formatRange(...splitPriceParamValue)
			return { type: 'price', valueName: formattedValues.replace(/^\~/, ''), value: priceParamValue };
		}
		return [];
	}

  constructPriceSlider() {
    const priceFilter = this.selectedFilters.find(filter => filter.type === 'price');
    const [selectedMin, selectedMax] = priceFilter?.value?.split('-') ?? [];

    this.updatePriceRangeDisplayText(priceFilter?.valueName ?? getCurrencyFormatter().formatRange(currentCollectionMinPrice, currentCollectionMaxPrice));

    $('.PriceSlider').slider({
			min: currentCollectionMinPrice,
			max: currentCollectionMaxPrice,
			range: true,
			values: [selectedMin ?? currentCollectionMinPrice, selectedMax ?? currentCollectionMaxPrice],
			slide: (e, ui) => {
				const $target = $(e.target);
        const [min, max] = ui.values;

        if (max-min < 500) {
          return false;
        }

				$target.data('rangeValue', ui.values);

				const currencyFormatter = getCurrencyFormatter();
				const priceRangeToShow = currencyFormatter.formatRange(Math.floor(min), Math.ceil(max));
				$target.data('valueName', priceRangeToShow);
				this.updatePriceRangeDisplayText(priceRangeToShow);

				$target.trigger('bm:slide');
			},
			change: (e) => {
				$(e.target).trigger('bm:change');
			}
		});
  }

  updatePriceRangeDisplayText(valueToShow) {
    const sanitizedValue = valueToShow.replace(/^\~/, '')
    $('.PriceRangeText').text(sanitizedValue);
  }

  hookEvents() {
    $(document).on('click', '.sorter-wrap', (e) => {
      if ( $(window).width() < 800 ) {
        this.injectSortModal();
        $('#sort-modal').fadeIn();
      }
    });

    $(document).on('click', '#sort-modal a.sc', (e) => {
      e.preventDefault();
      const $target = $(e.target);
      $('#sort-modal a.sc').removeClass('selected');
      var sort = $target.data('sort');
      $target.addClass('selected');
      $('#ring_sort').val(sort);
      $('#ring_sort')[0].dispatchEvent(new Event('change'));
      $('#sort-modal').fadeOut();
    });

    $(document).on('click', '#mobile-filter', (e) => {
      e.preventDefault();
      $('body > .mm-page').css('z-index',15);
      $('aside.filter-bar').fadeIn();
    });

    $(document).on('click', '#close-sort-modal', (e) => {
      e.preventDefault();
      $('#sort-modal').fadeOut();
    });

    $(document).on('click', '#close-filter', (e) => {
      e.preventDefault();
      $("aside.filter-bar").fadeOut();
      $("body > .mm-page").css("z-index",1);
    });

    $(document).on('click', '#reset-sort', () => {
      this.sortBy = 'newest';
      $('#ring_sort').val(this.sortBy);
      $('#ring_sort')[0].dispatchEvent(new Event('change'));
      $('#sort-modal').fadeOut();
    });

    $(document).on('click', '.filter-bar.collapsible section > h3', (event) => {
      var section = $(event.target).parent('section');
      if ( section.hasClass('collapsed') ) {
        $.each($('.filter-bar section').not('#' + section.attr('id')), function(k,v) {
          $(v).addClass('collapsed').find('.filters, .fp-text').slideUp();
        });
        section.removeClass('collapsed');
        section.find('.filters, .fp-text').slideDown();
      }
      else {
        section.addClass('collapsed');
        section.find('.filters, .fp-text').slideUp();
      }
    });

    $('#ring_sort').change(function(e) {
      e.preventDefault();
      const $target = $(e.target);
      this.sortBy = $target[0] && $target[0].selectedOptions[0] && $target[0].selectedOptions[0].value ? $target[0].selectedOptions[0].value : null;
      const sortText = $target[0].selectedOptions[0].innerText
      trigger_event('Sorted By', 'Product Listing Page', sortText, 1);

      const newURL = new URL(window.location);
      if (this.sortBy && this.sortBy !== 'newest') {
        newURL.searchParams.set('sort', this.sortBy);
      } else {
        newURL.searchParams.delete('sort');
      }
      window.location.href = newURL.href;
    });

    $(document).on('click', '#clear-filters', (e) => {
      e.preventDefault();
      const url = new URL(window.location);
      this.possible_filters.forEach(param => url.searchParams.delete(param));
      window.location = url;
    });

    $(document).on('click', '.ring-filter-tags a', (e) => {
      e.preventDefault();
      const $target = $(e.target);
      const $filterChipButton = $target.closest('a');
      const indexToRemove = this.getSelectedFilterIndex($filterChipButton.data('key'), $filterChipButton.data('value'));
      this.removeSelectedFilterAt(indexToRemove);
      this.updateDisplayedFilters();
      this.filtersChanged();
    });

    $(document).on('click', '.filter', (e) => {
      e.preventDefault();
      const $target = $(e.target);
      const $filterButton = $target.closest('.filter');

      const existingFilterIndex = this.getSelectedFilterIndex($filterButton.data('key'), $filterButton.data('value'));

      if (existingFilterIndex > -1) {
        this.removeSelectedFilterAt(existingFilterIndex);
      } else {
        trigger_event('Product Listing Page', `Filtered by ${$filterButton.data('keyName')}`, $filterButton.data('valueName'), 1);
        this.selectedFilters.push({
          type: $filterButton.data('key'),
          valueName: $filterButton.data('valueName'),
          value: $filterButton.data('value')
        });
      }

      this.updateDisplayedFilters();
      this.filtersChanged();
    });

    $(document).on('bm:change', '.PriceSlider', (e) => {
      const $target = $(e.target);
      const existingFilterIndex = this.selectedFilters.findIndex(filter => filter.type === 'price');

      if (existingFilterIndex > -1) {
        this.removeSelectedFilterAt(existingFilterIndex);
      }

      const priceRangeToShow = $target.data('valueName');
      trigger_event('Product Listing Page', `Filtered by price`, priceRangeToShow, 1);

      const [min, max] = $target.data('rangeValue');
      this.selectedFilters.push({
        type: 'price',
        valueName: priceRangeToShow,
        value: `${min}-${max}`
      });

      this.updateDisplayedFilters();
      this.filtersChanged();
    });

    $(window).on('resize', () => {
      const isSizedForMobile = window.innerWidth <= 800;
      $('.filter-bar').toggleClass('collapsible', isSizedForMobile);
      if (!isSizedForMobile) {
        $('.filter-bar section.clp .filters').removeAttr('style')
      }
    });
  }

  injectSortModal() {
    var html = `
        <div class="fullscreen-overlay" id="sort-modal">
          <div class="table modal-wrapper">
            <div class="table-cell">
              <div class="modal">
                <a id="close-sort-modal" class="icon-close"></a>
                <a href="#" class="sc" data-sort="new" id="reset-sort">Reset</a>
                <div class="links">
                  <a href="#" class="sc link${this.sortBy === 'newest' || !this.sortBy ? ' selected': ''}" data-sort="newest">Newest</a>
                  <a href="#" class="sc link${this.sortBy === 'best-sellers' ? ' selected': ''}" data-sort="best-sellers">Best Selling</a>
                  <a href="#" class="sc link${this.sortBy === 'price-high-low' ? ' selected': ''}" data-sort="price-high-low">Price: High to Low</a>
                  <a href="#" class="sc link${this.sortBy === 'price-low-high' ? ' selected': ''}" data-sort="price-low-high">Price: Low to High</a>
                </div>
              </div>
            </div>
          </div>
        </div>`;
    if ( $('#sort-modal').length == 0 ) {
      $('body').append(html);
    }
  }

  getSelectedFilterIndex(type, value) {
    return this.selectedFilters.findIndex(filter => filter.type === type && filter.value === value);
  }

  removeSelectedFilterAt(indexToRemove) {
    this.selectedFilters.splice(indexToRemove, indexToRemove === 0 ? 1 : indexToRemove);
  }

  updateDisplayedFilters() {
    const $filterTagsList = $('.ring-filter-tags');
    const newFilterHTML = this.selectedFilters.map(filter => this.buildFilterChip(filter)).join('');
    $filterTagsList.html(newFilterHTML);
    $filterTagsList.toggleClass('show', this.selectedFilters.length > 0);
    $('#clear-filters').toggle(this.selectedFilters.length > 0);

    const filterSelectors = this.selectedFilters.map(filter => `.filter[data-key="${filter.type}"][data-value="${filter.value}"]`);
    $('.filter').toggleClass('selected', false);
    $(filterSelectors.join(',')).toggleClass('selected', true);
  }

  setSortByFromURL() {
    const url = new URL(window.location);
    this.sortBy = url.searchParams.get('sort');
  }

  buildFilterChip(filter) {
    const $filterTag = $('<div></div>').innerHTML =
    `<li>
      <a href="#" data-key="${filter.type}" data-value="${filter.value}">
        <span class="icon-close"></span>
        <span class="tit">${filter.valueName}</span>
      </a>
    </li>`;

    return $filterTag;
  }

  filtersChanged() {
    const newURL = this.buildNewURL();
    window.location.href = newURL.href;
  }

  buildNewURL() {
    const params = this.selectedFilters.sort((a,b) => a.type.localeCompare(b.type)).reduce((params, filter) => {
      if (!params[filter.type]) {
        params[filter.type] = [];
      }
      params[filter.type].push(filter.value);
      return params;
    }, {});

    const newURL = new URL(window.location);
    this.possible_filters.forEach(filter => newURL.searchParams.delete(filter));
    Object.keys(params).forEach(paramKey => newURL.searchParams.set(paramKey, params[paramKey]));
    return newURL;
  }
}
